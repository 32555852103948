import React, { Component } from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"
import axios from "axios"
import { Metabox } from "../components/styles/NewsHeadingStyles"
import { format } from "date-fns"
import { PageContent } from "../templates/styles/archiveStyles"
import PageHeroLive from '../components/PageHeroLive'

class EventItem extends Component {
  state = {
    title: "",
    subtitle: "",
    date: new Date(),
    slug: "",
    content: "",
    media: "",
  }

  async componentDidMount() {
    const getEvent = await axios.get(
      `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/events?slug=${this.props.location.state.slug}`
    )
    const [getMedia] = await Promise.all([
      axios.get(
        `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/media/${getEvent.data[0].featured_media}`
      )
    ])
    this.setState({
      title: getEvent.data[0].title.rendered,
      subtitle: getEvent.data[0].acf.page_banner_subtitle,
      date: getEvent.data[0].date,
      slug: getEvent.data[0].slug,
      content: getEvent.data[0].content.rendered,
      // media: getMedia.data.guid.rendered
      media: getMedia.data.media_details.sizes.pageBanner.source_url
    })
  }
  render() {
    return (
      <Layout>
        <SEO title="Event" keywords={["secta", "tourism", "cornwall"]} />
        <PageHeroLive img={this.state.media} title={this.state.title} />
        <div className="container">
          <div className="row" style={{ marginBottom: "40px" }}>
            <div className="col-lg-3"/>
            <PageContent className="col-lg-9">
              <h2>{this.state.subtitle}</h2>
              <Metabox>
                <p>
                  On {format(new Date(this.state.date), "d MMM yyyy")}
                </p>
              </Metabox>
              <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </PageContent>
          </div>
        </div>
      </Layout>
    )
  }
}

export default EventItem

import React from "react"
import { BannerText, BannerImage } from "./styles/PageHeroStyles"

const PageHeroLive = ({ img, title, subTitle }) => (
  <div className="container-fluid">
    <div className="col" style={{ padding: 0 }}>
      {img === '' ? '' :
      <BannerImage src={img} />
      }
      <BannerText>
        <h1>{title}</h1>
        <article>{subTitle}</article>
      </BannerText>
    </div>
  </div>
)

export default PageHeroLive

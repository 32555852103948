import styled from "styled-components"

export const BannerText = styled.div`
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 20%;
  margin: 0 30%;
  color: #fff;
  text-shadow: 0 0 15px black;
  h1 {
    font-weight: 300;
    font-size: 5rem;
    @media (max-width: 1400px){
      font-size: 4rem;
    }
    @media (max-width: 1150px){
      font-size: 3rem;
    }
    @media (max-width: 575px){
      font-size: 2rem;
    }
    @media (max-width: 400px){
      font-size: 1rem;
    }
  }
  img {
    height: 350px
  }
  article {
    font-size: 1.4rem;
    @media (max-width: 1400px){
      display: none;
    }
  }
`
export const BannerImage = styled.img`
  width: 100%;
`;

import styled from "styled-components"
import Button from 'react-bootstrap/Button'

export const ButtonWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  top: 0;
  height: 100%;
  padding: 0 0 40px 0;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const Btn = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: blue;
  color: white;
  font-size: 20px;
  opacity: 0.7;
  border: 0;
  outline: none;
  border-radius: 50px;
  width: 250px;
  height: 50px;
  transition: all 0.2s;

  :hover {
    transform: scale(1.1);
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const Metabox = styled.div `
  background-color: beige;
  border-radius: 3px;
  padding: 10px 15px;
  display: inline-block;
  margin-bottom: 30px;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, .07);

  &--position-up {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  &--with-home-link {
    padding: 0;
  }

  &__main {
    padding: 10px 15px 10px 11px;
  }

  p {
    margin: 0;
    font-size: .9rem;
    color: color(beige l(-45%) s(-33%));
  }

  a {
    color: color(beige l(-45%) s(-33%));
    text-decoration: none;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }

  & &__blog-home-link {
    background-color: blue;
    color: #FFF;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 3px 0 0 3px;
    font-weight: normal;
  }

  & &__blog-home-link:hover {
    text-decoration: none;
    background-color: color(blue l(-5%));
  }
`;

export const H2Button = styled.h2`
  cursor: pointer;
`;
